import callBuilder from '../../utils/callBuilder'
import { reducer } from '../reducer'
import { callAction } from '../action'

export const getQuestions = () => {
  const route = '/professional/v1/questions'
  const prefix = 'GET_QUESTIONS'
  return callBuilder(route, prefix)
}

export const getAnswerHighlights = () => {
  const route = '/professional/v1/answerHighlights'
  const prefix = 'GET_ANSWER_HIGHLIGHTS'
  return callBuilder(route, prefix)
}

export const updateAnswerHighlights = highlights => {
  const route = '/professional/v1/answerHighlights'
  const prefix = 'UPDATE_ANSWER_HIGHLIGHTS'
  return callBuilder(route, prefix, 'post', { highlights })
}

export const deleteAllAnswerHighlights = () => {
  const route = '/professional/v1/answerHighlights'
  const prefix = 'DELETE_ANSWER_HIGHLIGHTS'
  return callBuilder(route, prefix, 'delete')
}

export const deleteAnswerHighlights = questionCategoryId => {
  const route = `/professional/v1/answerHighlights/${questionCategoryId}`
  const prefix = 'DELETE_CATEGORY_ANSWER_HIGHLIGHTS'
  return callBuilder(route, prefix, 'delete')
}

export const initialState = {
  questions: null,
  questionsInitialized: false,
  questionsError: false,
  answerHighlights: null,
  answerHighlightsInitialized: false,
  answerHighlightsError: false,
  updatingAnswerHighlights: false,
  updatingAnswerHighlightsError: false,
}

export default reducer(
  callAction('GET_QUESTIONS', action => ({
    attempt: { questionsError: false },
    failure: { questionsError: true },
    success: { questions: action.data, questionsInitialized: true },
  })),
  callAction('GET_ANSWER_HIGHLIGHTS', action => ({
    attempt: { answerHighlightsError: false },
    failure: { answerHighlightsError: true },
    success: { answerHighlights: action.data, answerHighlightsInitialized: true },
  })),
  callAction('UPDATE_ANSWER_HIGHLIGHTS', action => ({
    attempt: { updatingAnswerHighlights: true, updatingAnswerHighlightsError: false },
    failure: { updatingAnswerHighlights: false, updatingAnswerHighlightsError: true },
    success: { answerHighlights: action.data, updatingAnswerHighlights: false },
  })),
  callAction('DELETE_ANSWER_HIGHLIGHTS', action => ({
    success: { answerHighlights: action.data },
  })),
  callAction('DELETE_CATEGORY_ANSWER_HIGHLIGHTS', action => ({
    success: { answerHighlights: action.data },
  }))
)(initialState)
