import styled from 'styled-components'

const Padding = styled.div`
  padding-top: ${p => p.t ?? p.all ?? 0}px;
  padding-right: ${p => p.r ?? p.all ?? 0}px;
  padding-bottom: ${p => p.b ?? p.all ?? 0}px;
  padding-left: ${p => p.l ?? p.all ?? 0}px;
`

export default Padding
