import styled, { css } from 'styled-components'

// BASIC STYLES
export const bodyTextStyle = css`
  ${props => props.theme.font.body.regular};

  font-size: ${props => props.theme.font.fontSize.regular};
  color: ${props => props.theme.color.grey900};
`

export const headingTextStyle = css`
  ${props => props.theme.font.accent.bold};

  font-size: ${props => props.theme.font.fontSize.large};
  color: ${props => props.theme.color.grey900};
  margin: 0;
`
// HEADING STYLES
export const Heading1 = styled.h1`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.xxLarge};
`

export const Heading2 = styled.h2`
  ${headingTextStyle}
`

export const Heading3 = styled.h3`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.regular};
  color: ${props => props.theme.color.grey600};
`

export const Heading4 = styled.h4`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.small};
  color: ${props => props.theme.color.grey600};
`

export const Heading4Black = styled.h4`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.small};
`

export const Heading3Black = styled.h4`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.medium};
`

export const AccentHeading1 = styled.h3`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.xLarge};
  color: ${props => props.theme.color.white};
`

export const AccentHeading2 = styled.h3`
  ${headingTextStyle}
  color: ${props => props.theme.color.accent200};
`

// BODY TEXT STYLES
export const BodyText = styled.div`
  ${bodyTextStyle}

  ${props => getFontSize(props.theme, props.fontSize)}
`
export const BoldBodyText = styled(BodyText)`
  ${props => props.theme.font.body.medium};
`

export const Bolded = styled.span`
  font-weight: bold;
`

const getFontSize = (theme, fontSize = 'regular') => {
  const cssValue = theme.font.fontSize[fontSize]
  return `font-size: ${cssValue};`
}

// MISC STYLES
export const NumberHighlight = styled.div`
  ${headingTextStyle}

  font-size: ${props => props.theme.font.fontSize.huge};
`
