import React, { useEffect } from 'react'
import { i18n } from '@lingui/core'
import { I18nProvider as LinguiProvider } from '@lingui/react'
import { I18nProvider as AriaProvider } from 'react-aria'
import { useSelector } from 'react-redux'

import { messages as messagesFi } from '../../locales/fi/messages'
import { messages as messagesEn } from '../../locales/en/messages'
import { messages as messagesSv } from '../../locales/sv/messages'

const LocaleProvider = ({ children }) => {
  const lang = useSelector(s => s.settings.lang)

  useEffect(() => {
    i18n.load('fi', messagesFi)
    i18n.load('en', messagesEn)
    i18n.load('sv', messagesSv)
    i18n.loadLocaleData('fi', { plurals: {} })
    i18n.loadLocaleData('en', { plurals: {} })
    i18n.loadLocaleData('sv', { plurals: {} })
  }, [])

  useEffect(() => {
    i18n.activate(lang)
  }, [lang])

  return (
    <LinguiProvider i18n={i18n}>
      <AriaProvider locale={lang}>{children}</AriaProvider>
    </LinguiProvider>
  )
}

export default LocaleProvider
