import { t } from '@lingui/macro'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import EvexiaLogoRed from '../../assets/EvexiaLogoRed.svg'
import MenuIcon from '../../assets/MenuIcon.svg'
import { toggleSideBar } from '../../redux/reducers/ui'
import { Button, IconTextButton } from '../common/buttons'
import { basicShadow } from '../common/styles'
import EvaluationDialog from '../Patient/EvaluationDialog'

const TopBar = () => {
  // TODO: Check the current path and if we are looking at a patient only then show patient evaluation
  const hasPatient = useRouteMatch('/patients/:id')
  const dispatch = useDispatch()

  const [testOpen, setTestOpen] = React.useState(false)

  return (
    <>
      <TopBarBackground>
        <ButtonWrapper>
          <IconTextButton
            label={t`Valikko`}
            onClick={() => dispatch(toggleSideBar())}
            icon={<MenuIcon />}
            strokeColor
          />
          {hasPatient && <Button label={t`Arvioi potilas`} onClick={() => setTestOpen(true)} />}
        </ButtonWrapper>
        <NoticeText>{t`Vain tutkimuskäyttöön`}</NoticeText>
        <LogoWrapper>
          <EvexiaLogoRed />
        </LogoWrapper>
      </TopBarBackground>
      {testOpen && <EvaluationDialog onClose={() => setTestOpen(false)} />}
    </>
  )
}

const TopBarBackground = styled.header`
  background-color: ${props => props.theme.color.white};
  height: 48px;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  ${basicShadow};
`

const NoticeText = styled.span`
  font-size: ${props => props.theme.font.fontSize.small};
  color: ${props => props.theme.color.grey600};
`

const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const ButtonWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.xLarge};
  justify-content: flex-start;
  flex: 1;
`

export default TopBar
