import axios from 'axios'
import { REHYDRATE } from 'redux-persist'
import localforage from 'localforage'

import { API_URL, MAX_RETRY_ATTEMPTS } from '../config'

import { authInstance } from './auth'
import { sleep } from './general'

const SKIP_RETRY_CODES = [403, 401, 400, 404]
const AUTH_ERRORS = [401, 403]

const apiInstance = axios.create({ baseURL: API_URL, timeout: 30000 })

const callApi = async (url, method = 'get', data, query) => {
  const headers = {}
  return apiInstance({
    method,
    url,
    data,
    headers,
    params: query,
  })
}

const setToken = token => {
  apiInstance.defaults.headers.common['x-id-token'] = token
}

const retry = async (request, attempt = 1) => {
  try {
    const result = await request()
    return result
  } catch (e) {
    if (attempt >= MAX_RETRY_ATTEMPTS || SKIP_RETRY_CODES.includes(e.response?.status)) {
      throw e
    }

    await sleep(Math.min(attempt * 1000, 5000))
    return retry(request, attempt + 1)
  }
}

export const authMiddleware = () => next => async action => {
  const { type } = action
  if (type === 'RESPOND_TO_CHALLENGE_SUCCESS') {
    await localforage.setItem('refresh_token', action.payload.refreshToken)
    await localforage.setItem('email', authInstance.email)
    setToken(action.payload.idToken)
  } else if (type === REHYDRATE && action.payload) {
    const { idToken } = action.payload.auth
    if (idToken) setToken(idToken)
  } else if (type === 'SIGN_OUT') {
    await localforage.setItem('refresh_token', null)
    await localforage.setItem('email', null)
    setToken(null)
  }
  next(action)
}

export default (route, prefix, method = 'get', data, query) =>
  async dispatch => {
    const requestType = `${prefix}_ATTEMPT`

    dispatch({ type: requestType })
    try {
      const res = await retry(async () => callApi(route, method, data, query))
      dispatch({ type: `${prefix}_SUCCESS`, data: res.data, query })
    } catch (e) {
      const error = e?.response?.data?.error ?? e

      if (AUTH_ERRORS.includes(e?.response?.status)) {
        try {
          const refreshToken = await localforage.getItem('refresh_token')
          const email = await localforage.getItem('email')
          const { idToken } = await authInstance.refresh(email, refreshToken)
          setToken(idToken)

          const res = await callApi(route, method, data, query)
          dispatch({ type: `${prefix}_SUCCESS`, data: res.data, query })
          dispatch({ type: 'SET_ID_TOKEN', idToken })
          return
        } catch (e) {
          if (AUTH_ERRORS.includes(e?.response?.status) || !!e.code) {
            dispatch({ type: 'SIGN_OUT' })
          }
          return
        }
      }
      dispatch({ type: `${prefix}_FAILURE`, error, query })
    }
  }
