import React from 'react'
import { t } from '@lingui/macro'
import { TextInput } from '../common/inputs'

const PatientSearch = ({ searchValue, onSearchValueChange }) => {
  return (
    <TextInput
      value={searchValue}
      onChange={onSearchValueChange}
      placeholder={`${t`Potilashaku`} - ${t`Kirjoita vähintään 3 merkkiä`}`}
    />
  )
}

export default PatientSearch
