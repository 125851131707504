import React from 'react'

import {
  DismissButton,
  Overlay,
  useButton,
  useCalendarCell,
  useCalendarGrid,
  useDateField,
  useDateRangePicker,
  useDateSegment,
  useDialog,
  useLocale,
  usePopover,
  useRangeCalendar,
} from 'react-aria'

import { useDateFieldState, useDateRangePickerState, useRangeCalendarState } from 'react-stately'

import {
  GregorianCalendar,
  getDayOfWeek,
  getLocalTimeZone,
  getWeeksInMonth,
  isSameDay,
  today,
} from '@internationalized/date'
import { t } from '@lingui/macro'
import styled from 'styled-components'
import CalendarIcon from '../../assets/Calendar.svg'
import { IconButton, ToggleButton } from './buttons'
import { Heading2, bodyTextStyle, headingTextStyle } from './typography'

const DateRangePicker = props => {
  const state = useDateRangePickerState(props)
  const ref = React.useRef(null)
  const { labelProps, groupProps, startFieldProps, endFieldProps, buttonProps, dialogProps, calendarProps } =
    useDateRangePicker(props, state, ref)

  const todayDate = today(getLocalTimeZone())
  const twoWeeksRange = { start: today(getLocalTimeZone()).subtract({ weeks: 2 }), end: todayDate }
  const fourWeeksRange = { start: today(getLocalTimeZone()).subtract({ weeks: 4 }), end: todayDate }
  const allRange = { start: null, end: todayDate }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <InfoText {...labelProps}>{props.label}</InfoText>
      <div style={{ display: 'flex', gap: '8px' }}>
        <DateRangePickerContainer isInvalid={state.validationState === 'invalid'} {...groupProps} ref={ref}>
          <DateRangeInputContainer className="field">
            <DateField {...startFieldProps} hideTimeZone />
            <span style={{ padding: '0 4px' }}>–</span>
            <DateField {...endFieldProps} hideTimeZone />
          </DateRangeInputContainer>
          <IconButton {...buttonProps} icon={<CalendarIcon />} />
        </DateRangePickerContainer>
        <ToggleButton
          onChange={() => props.onChange(twoWeeksRange)}
          isSelected={isSameRange(props.value, twoWeeksRange)}
        >
          {t`2 viikkoa`}
        </ToggleButton>
        <ToggleButton
          onChange={() => props.onChange(fourWeeksRange)}
          isSelected={isSameRange(props.value, fourWeeksRange)}
        >
          {t`4 viikkoa`}
        </ToggleButton>
        <ToggleButton onChange={() => props.onChange(allRange)} isSelected={isSameRange(props.value, allRange)}>
          {t`Kaikki`}
        </ToggleButton>
      </div>
      {state.validationState === 'invalid' && (
        <InfoText aria-hidden="true">{t`Päivämääräväli on virheellinen!`}</InfoText>
      )}

      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          <Dialog {...dialogProps}>
            <RangeCalendar {...calendarProps} />
          </Dialog>
        </Popover>
      )}
    </div>
  )
}

const InfoText = styled.span`
  ${bodyTextStyle}
`

const Button = props => {
  const ref = React.useRef(null)
  const { buttonProps } = useButton(props, ref)
  return (
    <button {...buttonProps} ref={ref} type="button">
      {props.children}
    </button>
  )
}

const isSameRange = (range, comparedRange) => {
  if (
    range &&
    comparedRange &&
    range.start === null &&
    range.start === comparedRange.start &&
    range.end.compare(comparedRange.end) === 0
  ) {
    return true
  }
  if (
    range &&
    comparedRange &&
    range.start &&
    comparedRange.start &&
    range.start.compare(comparedRange.start) === 0 &&
    range.end.compare(comparedRange.end) === 0
  ) {
    return true
  }

  return false
}

const DateRangeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

const DateRangePickerContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  height: 32px;
  min-width: 200px;

  background: #ffffff;

  border: 1px solid ${({ theme, isInvalid }) => (isInvalid ? theme.color.primary800 : theme.color.grey600)};
  box-sizing: border-box;

  box-shadow: 0px 2px 2px rgba(148, 136, 136, 0.25);
  border-radius: 5px;

  ${bodyTextStyle}
`

const createCalendar = identifier => {
  switch (identifier) {
    case 'gregory':
      return new GregorianCalendar()
    default:
      throw new Error(`Unsupported calendar ${identifier}`)
  }
}

const DateField = props => {
  const { locale } = useLocale()
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = React.useRef(null)
  const { labelProps, fieldProps } = useDateField(props, state, ref)

  return (
    <div className="wrapper" style={{ display: 'flex' }}>
      <span {...labelProps}>{props.label}</span>
      <div {...fieldProps} ref={ref} className="field" style={{ display: 'flex' }}>
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  )
}

const DateSegment = ({ segment, state }) => {
  const ref = React.useRef(null)
  const { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <div {...segmentProps} ref={ref} className={`segment ${segment.isPlaceholder ? 'placeholder' : ''}`}>
      {segment.text}
    </div>
  )
}

const Popover = ({ children, state, ...props }) => {
  const popoverRef = React.useRef(null)
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state
  )

  return (
    <Overlay>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <StyledPopover {...popoverProps} ref={popoverRef} defaultStyles={popoverProps.style}>
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </StyledPopover>
    </Overlay>
  )
}

const StyledPopover = styled.div`
  ${({ defaultStyles }) => defaultStyles}
  background-color: ${({ theme }) => theme.color.white};
  border: solid 1px ${({ theme }) => theme.color.grey600};
  border-radius: ${({ theme }) => theme.spacing.small};
`

function Dialog({ title, children, ...props }) {
  const ref = React.useRef(null)
  const { dialogProps, titleProps } = useDialog(props, ref)

  return (
    <DialogContainer {...dialogProps} ref={ref}>
      {title && (
        <Heading2 {...titleProps} style={{ marginTop: 0 }}>
          {title}
        </Heading2>
      )}
      {children}
    </DialogContainer>
  )
}

const DialogContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`

function RangeCalendar(props) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const ref = React.useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(props, state, ref)

  return (
    <RangeCalendarContainer {...calendarProps} ref={ref} className="calendar">
      <RangeCalendarTitleRow className="header">
        <Button {...prevButtonProps}>&lt;</Button>
        <Heading2 style={{ textTransform: 'capitalize' }}>{title}</Heading2>
        <Button {...nextButtonProps}>&gt;</Button>
      </RangeCalendarTitleRow>
      <CalendarGrid state={state} />
    </RangeCalendarContainer>
  )
}

const RangeCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.large};
`

const RangeCalendarTitleRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.large};
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
`

function CalendarGrid({ state, ...props }) {
  const { locale } = useLocale()
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)

  return (
    <CalendarGridTable {...gridProps} cellPadding={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <CalendarDayHeader key={index}>{day}</CalendarDayHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) => (date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />))}
          </tr>
        ))}
      </tbody>
    </CalendarGridTable>
  )
}

const CalendarDayHeader = styled.th`
  aspect-ratio: 1 / 1;
  ${headingTextStyle}
`

const CalendarGridTable = styled.table`
  width: 200px;
  border-spacing: 0 ${({ theme }) => theme.spacing.regular};
`

function CalendarCell({ state, date }) {
  const ref = React.useRef(null)
  const { locale } = useLocale()
  const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, isUnavailable, formattedDate } =
    useCalendarCell({ date }, state, ref)
  const dayOfWeek = getDayOfWeek(date, locale)

  const isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected
  const isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected
  const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1)
  const isRoundedRight =
    isSelected && (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date))
  const isFullyRounded = isSelectionStart && isSelectionEnd

  return (
    <td {...cellProps} style={{ padding: 0 }}>
      <DayCell
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        isSelected={isSelected}
        isRoundedLeft={isRoundedLeft}
        isRoundedRight={isRoundedRight}
        isFullyRounded={isFullyRounded}
        isStartOrEnd={isSelectionStart || isSelectionEnd}
        className={`cell ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''} ${
          isUnavailable ? 'unavailable' : ''
        }`}
      >
        {formattedDate}
      </DayCell>
    </td>
  )
}

const DayCell = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  ${bodyTextStyle}
  font-size: ${props => props.theme.font.fontSize.large};

  padding: ${({ theme }) => theme.spacing.regular};

  background-color: ${({ theme, isSelected }) => (isSelected ? theme.color.accent200 : 'none')};
  background-color: ${({ theme, isStartOrEnd }) => (isStartOrEnd ? theme.color.accent800 : 'none')};
  color: ${({ theme, isStartOrEnd }) => (isStartOrEnd ? theme.color.white : theme.color.grey900)};
  border-radius: ${({ isRoundedLeft, isRoundedRight, isFullyRounded }) =>
    isFullyRounded ? '100%' : isRoundedRight ? '0 100% 100% 0' : isRoundedLeft ? '100% 0 0 100%' : 0}; ;
`

export default DateRangePicker
