import React from 'react'

const apiUrl = process.env.API_URL
const builtAt = process.env.BUILT_AT

const Info = () => {
  return (
    <div>
      <p>api url: {apiUrl}</p>
      <p>built at: {builtAt}</p>
    </div>
  )
}

export default Info
