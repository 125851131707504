import createTrend from 'trendline'

import { compareDates, parseDate, parseDateKey, getDatesBetween } from './date'

export const makeBarData = (patientSteps, maxSteps, locale) => {
  if (!patientSteps?.length) return []

  const highlightData = makeHighlightData(patientSteps)
  const averageSteps = highlightData.avg

  const orderedSteps = [...patientSteps]
    .sort((a, b) => compareDates(a.steps_date, b.steps_date))
    .map(s => ({ ...s, dateKey: parseDateKey(s.steps_date) }))

  const stepsByDateKey = orderedSteps.reduce((res, curr) => ({ ...res, [curr.dateKey]: curr }), {})

  const startDate = new Date(orderedSteps[0].steps_date)
  const endDate = new Date(orderedSteps[orderedSteps.length - 1].steps_date)
  const datesBetween = getDatesBetween(startDate, endDate, true)

  const parsedStepsPerDate = datesBetween.map(date => {
    const parsedDate = parseDate(date, locale)
    const steps = stepsByDateKey[parseDateKey(date)]?.steps

    if (steps)
      return { date: parsedDate.dateNoYear, dateYear: parsedDate.date, steps, dateTime: Date.parse(new Date(date)) }
    return {
      date: parsedDate.dateNoYear,
      dateYear: parsedDate.date,
      missing: maxSteps,
      steps: averageSteps,
      dateTime: Date.parse(new Date(date)),
    }
  })

  const trendData = () => {
    const trend = createTrend(parsedStepsPerDate, 'dateTime', 'steps')

    const parsedDataWithTrendValues = parsedStepsPerDate.map(date => {
      if (date.missing) {
        return {
          trendSteps: trend.calcY(new Date(date.dateTime)),
          missing: date.missing,
          date: date.date,
          dateYear: date.dateYear,
          dateTime: date.dateTime,
        }
      }

      return {
        steps: date.steps,
        trendSteps: trend.calcY(new Date(date.dateTime)),
        missing: date.missing,
        date: date.date,
        dateYear: date.dateYear,
        dateTime: date.dateTime,
      }
    })

    return parsedDataWithTrendValues
  }

  return trendData()
}

/**
 * Gives min, max, avg and sum of steps for a given set of steps. If empty array given returns undefined values.
 * @param {array} patientSteps - Array with steps objects.
 */
export const makeHighlightData = patientSteps => {
  if (!patientSteps?.length) return { min: undefined, max: undefined, avg: undefined, sum: undefined }
  const steps = [...patientSteps.map(s => s.steps)].sort((a, b) => a - b)

  const mid = Math.floor(steps.length / 2)
  const median = steps.length % 2 !== 0 ? steps[mid] : (steps[mid - 1] + steps[mid]) / 2

  const sum = steps.reduce((a, b) => a + b, 0)
  const avg = sum / steps.length
  const min = Math.min(...steps)
  const max = Math.max(...steps)

  return { min, max, avg, sum, median }
}
