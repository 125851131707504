import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getQuestions, getAnswerHighlights } from '../redux/reducers/question'
import { useAction } from '../utils/hooks'

import Router from './Router'

const MainContainer = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.color.grey50};
`

const Main = () => {
  const dispatchGetQuestions = useAction(getQuestions)
  const dispatchGetAnswerHighlights = useAction(getAnswerHighlights)

  const hasAccess = useSelector(s => s.professional.hasAccess)

  useEffect(() => {
    if (hasAccess) {
      dispatchGetQuestions()
      dispatchGetAnswerHighlights()
    }
  }, [hasAccess])

  return (
    <MainContainer>
      <Router />
    </MainContainer>
  )
}

export default Main
