import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { OverlayProvider } from 'react-aria'
import { ToastContainer } from 'react-toastify'

import { store, persistor } from './redux/store'

import Main from './components/Main'
import AuthGate from './components/Auth/AuthGate'
import './index.css'
import './theme/fonts.css'
import 'react-toastify/dist/ReactToastify.css'
import theme from './theme/theme'
import LocaleProvider from './components/LocaleProvider'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthGate>
            {/* TODO: Remove this hack and find the reason why this is needed */}
            <OverlayProvider style={{ width: '100%' }}>
              <LocaleProvider>
                <Main />
                <ToastContainer
                  position="top-right"
                  autoClose={4000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss
                  pauseOnHover
                />
              </LocaleProvider>
            </OverlayProvider>
          </AuthGate>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
