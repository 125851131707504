import { authInstance } from '../../utils/auth'
import { reducer } from '../reducer'
import { action, callAction } from '../action'

export const showPolicies = email => ({
  type: 'SHOW_POLICIES',
  email,
})

export const acceptPolicies = () => {
  return (dispatch, getState) => {
    const stateBefore = getState()
    // Check if user is currently logging in for the first time.
    // If so, finish authentication.
    if (stateBefore.auth.signInPending) {
      dispatch(signUp(stateBefore.auth.email))
    }
  }
}

const signUp = email => {
  return async dispatch => {
    dispatch({ type: 'SIGN_UP_ATTEMPT' })
    try {
      await authInstance.signUp(email)
      dispatch({ type: 'SIGN_UP_SUCCESS' })
      dispatch(signIn(email, false))
    } catch (e) {
      dispatch({ type: 'SIGN_IN_FAILURE' })
      dispatch({ type: 'SIGN_UP_FAILURE' })
    }
  }
}

export const signIn = (email, retry = true) => {
  return async dispatch => {
    dispatch({ type: 'SIGN_IN_ATTEMPT' })
    try {
      await authInstance.signIn(email)
      dispatch({ type: 'SIGN_IN_SUCCESS', email })
    } catch (e) {
      // A bit of a hack, but oh well
      if (e?.message?.includes('User not found') && retry) {
        dispatch(showPolicies(email))
      } else dispatch({ type: 'SIGN_IN_FAILURE' })
    } finally {
      if (!retry) dispatch({ type: 'HIDE_POLICIES' })
    }
  }
}

export const respondToChallenge = code => {
  return async dispatch => {
    dispatch({ type: 'RESPOND_TO_CHALLENGE_ATTEMPT' })
    try {
      const tokens = await authInstance.repondToChallenge(code)
      dispatch({ type: 'RESPOND_TO_CHALLENGE_SUCCESS', payload: tokens })
    } catch (e) {
      dispatch({ type: 'RESPOND_TO_CHALLENGE_FAILURE' })
    }
  }
}

export const signOut = () => ({
  type: 'SIGN_OUT',
})

export const initialState = {
  idToken: null,
  accessToken: null,
  signUpPending: false,
  signUpError: false,
  signInPending: false,
  signInError: false,
  respondToChallengePending: false,
  respondToChallengeError: false,
  showPolicies: false,
  email: null,
}

export default reducer(
  callAction('SIGN_UP', () => ({
    attempt: { signUpPending: true, signUpError: false },
    failure: { signUpPending: false, signUpError: true },
    success: { signUpPending: false },
  })),
  callAction('SIGN_IN', action => ({
    attempt: { signInPending: true, signInError: false },
    failure: { signInPending: false, signInError: true },
    success: { signInPending: false, email: action.email },
  })),
  callAction('RESPOND_TO_CHALLENGE', action => ({
    attempt: { respondToChallengePending: true, respondToChallengeError: false },
    failure: { respondToChallengePending: false, respondToChallengeError: true },
    success: {
      respondToChallengePending: false,
      idToken: action?.payload?.idToken,
      accessToken: action?.payload?.accessToken,
    },
  })),
  action('SET_ID_TOKEN', action => ({
    idToken: action.idToken,
  })),
  action('SHOW_POLICIES', action => ({
    showPolicies: true,
    email: action.email,
  })),
  action('HIDE_POLICIES', () => ({
    showPolicies: false,
  }))
)(initialState)
