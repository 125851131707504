import callBuilder from '../../utils/callBuilder'
import { reducer } from '../reducer'
import { callAction } from '../action'

export const getHasAccess = () => {
  const route = '/professional/v1/hasAccess'
  const prefix = 'HAS_ACCESS'
  return callBuilder(route, prefix)
}

export const activateAccessCode = accessCode => {
  const route = '/professional/v1/activateCode'
  const prefix = 'ACTIVATE_ACCESS_CODE'
  const method = 'post'
  const body = { accessCode }
  return callBuilder(route, prefix, method, body)
}

export const initialState = {
  hasAccessInitialized: false,
  hasAccess: false,
  organization: null,
  activateAccessCodePending: false,
  activateAccessCodeError: false,
}

export default reducer(
  callAction('HAS_ACCESS', action => {
    return {
      success: { hasAccessInitialized: true, hasAccess: action.data?.result, organization: action.data?.organization },
    }
  }),
  callAction('ACTIVATE_ACCESS_CODE', action => ({
    attempt: { activateAccessCodePending: true, activateAccessCodeError: false },
    failure: { activateAccessCodePending: false, activateAccessCodeError: true },
    success: { activateAccessCodePending: false, hasAccess: true, organization: action.data?.organization },
  }))
)(initialState)
