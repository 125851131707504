import { css } from 'styled-components'

export const basicShadowSmall = css`
  box-shadow: 0px 1px 1px rgba(148, 136, 136, 0.125);
`

export const basicShadow = css`
  box-shadow: 0px 1px 1px rgba(148, 136, 136, 0.125), 0px 2px 2px rgba(148, 136, 136, 0.125);
`

export const basicShadowLarge = css`
  box-shadow: 0px 1px 1px rgba(148, 136, 136, 0.125), 0px 2px 2px rgba(148, 136, 136, 0.125),
    0px 4px 4px rgba(148, 136, 136, 0.125);
`
