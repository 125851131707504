import React, { useCallback } from 'react'
import styled from 'styled-components'
import PlusIcon from '../../assets/Plus.svg'
import MinusIcon from '../../assets/Minus.svg'
import { Heading1, Heading4 } from './typography'
import Column from './Column'
import Spacing from './Spacing'
import { basicShadowLarge } from './styles'
import { useTheme } from '../../utils/hooks'
import DangerIcon from '../../assets/Danger.svg'

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 128px auto;
  grid-template-rows: 24px auto;
  justify-items: stretch;

  border-radius: ${p => p.theme.spacing.small} ${p => p.theme.spacing.small};
  cursor: ${p => (p.collapsible ? 'pointer' : 'default')};

  background-color: ${p => p.theme.color.white};

  ${basicShadowLarge}
`

const CardHeaderContainer = styled.div`
  background-color: ${p => p.theme.color.accent600};
  border-radius: ${p => (!p.open ? '4px 0px 0px 4px' : '4px 0px')};
  grid-row: 1;
  grid-column: 1;
  padding-left: ${p => p.theme.spacing.large};
  display: flex;
  align-items: center;
`

const CardHeaderInfo = styled.div`
  grid-row: 1;
  grid-column: 2 / 3;
  padding-left: ${p => p.theme.spacing.large};
  display: flex;
  align-items: center;
`

const CardHeaderButton = styled.div`
  grid-row: 1;
  grid-column: 3;
  margin-left: ${p => p.theme.spacing.large};
  align-self: center;
  justify-self: end;
  margin-right: ${p => p.theme.spacing.large};
  width: fit-content;
`

const CardHeader = styled(Heading1)`
  color: white;
  font-size: ${props => props.theme.font.fontSize.large};
`

const CardContentContainer = styled(Column)`
  padding-top: ${p => p.theme.spacing.small};
  padding-left: ${p => p.theme.spacing.large};
  padding-right: ${p => p.theme.spacing.large};
  padding-right: ${p => p.theme.spacing.large};
  grid-row: ${p => (p.hideHeader ? '1 / 3' : 2)};
  grid-column: 1 / end;
`

const Card = ({
  title,
  children,
  collapsible = false,
  open = true,
  info,
  highlight,
  onClick,
  hideHeader = false,
  style = {},
  action,
  ...p
}) => {
  const theme = useTheme()

  const handleClick = useCallback(() => {
    if (onClick) onClick()
  }, [onClick])

  return (
    <CardContainer style={style} collapsible={collapsible} onClick={handleClick} {...p}>
      {!hideHeader && (
        <CardHeaderContainer open={open}>
          <CardHeader>{title}</CardHeader>
        </CardHeaderContainer>
      )}
      {!!(info || highlight) && (
        <CardHeaderInfo>
          {!!info && <Heading4>{info}</Heading4>}
          <Spacing />
          {!!highlight && (
            <>
              <Heading4>
                <DangerIcon />
              </Heading4>
              <Spacing />
              <Heading4 style={{ color: theme.color.primary600 }}>{highlight}</Heading4>
            </>
          )}
        </CardHeaderInfo>
      )}
      {action && <CardHeaderButton>{action}</CardHeaderButton>}
      {collapsible && <CardHeaderButton>{open ? <MinusIcon /> : <PlusIcon />}</CardHeaderButton>}
      {open && <CardContentContainer hideHeader={hideHeader}>{children}</CardContentContainer>}
    </CardContainer>
  )
}

export const FlexCard = styled(Card)`
  flex: 1;
`

export default Card
