import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useButton, useToggleButton } from 'react-aria'
import { useToggleState } from 'react-stately'

import Spacing from './Spacing'
import { basicShadow, basicShadowLarge, basicShadowSmall } from './styles'
import Column from './Column'

const ButtonWrapper = ButtonComponent =>
  function Btn(props) {
    const ref = useRef()
    const { onClick, disabled, ...p } = props
    const { buttonProps } = useButton(props, ref)
    // TODO: Add debounce

    const handleClick = useCallback(() => {
      if (!disabled) onClick()
    }, [onClick, disabled])

    return <ButtonComponent onPress={handleClick} disabled={disabled} {...p} {...buttonProps} />
  }

export const Button = ButtonWrapper(({ label, ...p }) => {
  return <ButtonStyle {...p}>{label}</ButtonStyle>
})

export const Clickable = ({ children, ...p }) => {
  const ref = useRef()
  const { buttonProps } = useButton(
    {
      ...p,
      elementType: 'span',
    },
    ref
  )

  return (
    <span
      {...buttonProps}
      style={{
        cursor: 'pointer',
      }}
      ref={ref}
    >
      {children}
    </span>
  )
}

export const ToggleButton = props => {
  const ref = useRef()
  const state = useToggleState(props)

  const { buttonProps } = useToggleButton(props, state, ref)
  return (
    <ToggleButtonContainer {...buttonProps} isSelected={props.isSelected}>
      {props.children}
    </ToggleButtonContainer>
  )
}

const ToggleButtonContainer = styled.button`
  border-radius: ${p => p.theme.spacing.small};
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.accent600 : theme.color.grey200)};
  border: none;
  color: ${({ isSelected, theme }) => (isSelected ? theme.color.grey50 : theme.color.grey800)};

  padding: 8px 16px;
  font-size: ${props => props.theme.font.fontSize.regular};
  ${props => hoverStyles(props.disabled)}
`

export const ClickableToggle = ({ children, ...p }) => {
  const ref = useRef()

  const state = useToggleState(p)
  const { buttonProps } = useToggleButton(
    {
      ...p,
      elementType: 'span',
    },
    state,
    ref
  )

  return (
    <span
      {...buttonProps}
      style={{
        cursor: 'pointer',
      }}
      ref={ref}
    >
      {children}
    </span>
  )
}

const hoverStyles = (disabled, shadow = false, disabledOpacity = 0.7, hoverOpacity = 0.9, activeScale = 0.98) => {
  return css`
    opacity: ${() => (disabled ? disabledOpacity : 1.0)};
    transition: transform 250ms, box-shadow 250ms, opacity 300ms;

    ${props =>
      !props.disabled &&
      css`
        :hover {
          ${shadow && basicShadowLarge};
          opacity: ${hoverOpacity};
          cursor: pointer;
        }
      `}

    :active {
      transform: ${() => `scale(${activeScale})`};
      ${shadow && basicShadowSmall}
    }
  `
}

const ButtonStyle = styled.button`
  ${props => props.theme.font.accent.extraBold};
  ${basicShadow}

  font-size: ${props => props.theme.font.fontSize.regular};
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.accent600};
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.large};
  text-transform: uppercase;
  border-radius: 25px;
  border: 0;
  justify-content: center;
  align-items: center;

  ${props => hoverStyles(props.disabled, true)}
`

export const TextButton = ButtonWrapper(({ label, ...p }) => {
  return <TextButtonStyle {...p}>{label}</TextButtonStyle>
})

const TextButtonStyle = styled.button`
  ${props => props.theme.font.accent.bold};

  background-color: transparent;
  font-size: ${props => props.theme.font.fontSize.regular};
  color: ${props => props.theme.color.grey600};
  border: 0;
  justify-content: center;
  align-items: center;

  padding: ${props => props.theme.spacing.regular};

  ${props => hoverStyles(props.disabled, false, 0.7, 0.75)}
`

export const IconTextButton = ButtonWrapper(({ label, icon, accent = false, strokeColor = false, ...p }) => {
  return (
    <IconTextButtonStyle accent={accent} strokeColor={strokeColor} {...p}>
      {icon}
      <Spacing dir="x" />
      <Column justifyContent="center">{label}</Column>
    </IconTextButtonStyle>
  )
})

const IconTextButtonStyle = styled.button`
  display: flex;
  ${props => props.theme.font.accent.bold};

  background-color: transparent;
  font-size: ${props => props.theme.font.fontSize.regular};

  color: ${props => (props.accent ? props.theme.color.white : props.theme.color.grey900)};
  fill: ${props => (props.accent ? props.theme.color.white : props.theme.color.grey900)};
  stroke: ${props =>
    props.strokeColor ? (props.accent ? props.theme.color.white : props.theme.color.grey900) : 'none'};
  border: 0;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  flex-direction: row;

  padding: ${props => props.theme.spacing.regular};

  ${props => hoverStyles(props.disabled, false, 0.7, 0.75)}
`

export const IconButton = ButtonWrapper(({ icon, accent = false, ...p }) => {
  return (
    <IconButtonStyle accent={accent} {...p}>
      {icon}
    </IconButtonStyle>
  )
})

const IconButtonStyle = styled.button`
  background-color: transparent;

  color: ${props => (props.accent ? props.theme.color.white : props.theme.color.grey900)};
  fill: ${props => props.theme.color.grey900};
  stroke: ${props => props.theme.color.grey900};
  border: 0;
  border-radius: 100%;

  width: fit-content;

  justify-content: center;
  align-items: center;
  display: flex;

  padding: ${props => props.theme.spacing.small};

  transition: transform 200ms, opacity 300ms;

  :hover {
    opacity: 0.6;
    cursor: pointer;
  }
  :active {
    transform: scale(0.98);
  }
`
