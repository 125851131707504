import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { strIncludes } from '../../utils/general'
import { useDebounce, useInput } from '../../utils/hooks'
import Column from '../common/Column'
import Spacing from '../common/Spacing'
import { BodyText, Bolded } from '../common/typography'
import PatientProfile from './PatientProfile'
import PatientSearch from './PatientSearch'

const PatientList = () => {
  const [searchValue, setSearchValue] = useInput('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const debouncedSearchValueLongEnough = debouncedSearchValue.length >= 3

  const patientProfiles = useSelector(s => s.patient.patients?.profiles)
  const patientCodes = useSelector(s => s.patient.patients?.codes)

  const filteredProfiles = useMemo(() => {
    if (!debouncedSearchValueLongEnough) return []

    return patientProfiles.filter(profile => {
      const code = patientCodes[profile.userId] ?? null

      const birthYear = `${profile.birthYear}`
      const searchParams = searchValue.trim().split(/\s+/)

      let f = true
      searchParams.forEach(param => {
        if (!(strIncludes(code, param) || strIncludes(birthYear, param))) f = false
      })

      return f
    })
  }, [debouncedSearchValue, patientProfiles])

  return (
    <Column>
      <PatientSearch searchValue={searchValue} onSearchValueChange={setSearchValue} />
      <Spacing dir="y" />
      {filteredProfiles.length > 0
        ? filteredProfiles.map(profile => (
            <PatientProfile key={profile.userId} profile={profile} code={patientCodes[profile.userId]} />
          ))
        : debouncedSearchValueLongEnough && (
            <BodyText>
              {t`Haulla`}
              {` `}
              <Bolded>{debouncedSearchValue}</Bolded>
              {` `}
              {t`ei löytynyt yhtään potilasta.`}
            </BodyText>
          )}
    </Column>
  )
}

export default PatientList
