import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '@lingui/macro'
import styled from 'styled-components'

import { getPatients } from '../../redux/reducers/patient'
import PageContainer from '../common/PageContainer'
import { Heading1, BodyText, Bolded, Heading3 } from '../common/typography'
import Row from '../common/Row'
import Column from '../common/Column'
import Spacing from '../common/Spacing'
import PatientList from './PatientList'
import PatientProfile from './PatientProfile'
import SurveyCard from './SurveyCard'

const Home = () => {
  const dispatch = useDispatch()

  const getPatientsInitialized = useSelector(s => s.patient.getPatientsInitialized)
  const getPatientsError = useSelector(s => s.patient.getPatientsError)
  const patientProfiles = useSelector(s => s.patient.patients?.profiles)
  const patientCodes = useSelector(s => s.patient.patients?.codes)
  const history = useSelector(s => s.patient.patients?.history)

  const profileByUserId = useMemo(() => {
    return (
      patientProfiles?.reduce((res, curr) => {
        res[curr.userId] = curr
        return res
      }, {}) ?? {}
    )
  }, [patientProfiles])

  useEffect(() => {
    dispatch(getPatients())
  }, [])

  if (!getPatientsInitialized) return <p>{t`Ladataan potilaita...`}</p>
  if (getPatientsError) return <p>{t`Potilaiden latauksessa tapahtui virhe`}</p>
  if (!patientProfiles.length) return <p>{t`Ei potilaita`}</p>

  return (
    <PageContainer>
      <Spacing dir="y" amount="large" />

      <StyledRow>
        <StyledColumn>
          <Heading1>{t`Potilashaku`}</Heading1>
          <Spacing dir="y" />

          <BodyText>
            {t`Valitse potilas, jonka tietoja haluat tarkastella. Voit hakea potilaita allaolevalla hakukentällä käyttäen potilaan`}{' '}
            <Bolded>{t`Evexia-koodia`} </Bolded> {t`tai`} <Bolded>{t`syntymävuotta`}</Bolded>.
          </BodyText>
          <Spacing dir="y" />

          <BodyText>{t`Haku näyttää tuloksia sitä mukaa, kun kirjoitat hakusanoja hakukenttään.`}</BodyText>
          <Spacing dir="y" />

          <PatientList />
        </StyledColumn>

        <StyledColumn>
          <SurveyCard />
          <Spacing dir="y" />

          <Heading1>{t`Katseluhistoria`}</Heading1>
          <Spacing dir="y" />

          <BodyText>
            {t`Alla näet potilaat, joiden tietoja olet katsonut aikaisemmin. Näkymä näyttää enintään kerrallaan viisi edellistä potilasta.`}
          </BodyText>
          <Spacing dir="y" amount="large" />

          {!history.length && <Heading3>{t`Ei aikaisemmin katseltuja potilaita`}</Heading3>}
          {history.map(userId => (
            <PatientProfile key={userId} profile={profileByUserId[userId]} code={patientCodes[userId]} />
          ))}
        </StyledColumn>
      </StyledRow>
    </PageContainer>
  )
}

const StyledRow = styled(Row)`
  gap: min(${props => props.theme.spacing.xxLarge}, 4vw);
  justify-content: center;
`

const StyledColumn = styled(Column)`
  max-width: min(70ch, 45vw);
`

export default Home
