import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import { useRouteMatch } from 'react-router-dom'
import { Transition, TransitionGroup } from 'react-transition-group'

import { basicShadow } from '../common/styles'
import { IconTextButton } from '../common/buttons'
import { toggleSideBar } from '../../redux/reducers/ui'
import { signOut } from '../../redux/reducers/auth'
import LogOutIcon from '../../assets/LogOut.svg'
// import SettingsIcon from '../../assets/Settings.svg' // TODO: COMMENTED OUT TEMPORARILY
import SwitchPatientIcon from '../../assets/SwitchPatient.svg'
import { AccentHeading1, AccentHeading2 } from '../common/typography'
import { useAction, useGetValue, useGoTo } from '../../utils/hooks'

const TIMEOUT_MS = 100

const SideBar = () => {
  const getValue = useGetValue()
  const goToMenu = useGoTo('/')
  // const goToSettings = useGoTo('/settings') // TODO: COMMENTED OUT TEMPORARILY
  const dispatchToggleSideBar = useAction(toggleSideBar)
  const dispatchSignOut = useAction(signOut)

  const hasPatient = useRouteMatch('/patients/:id')

  const email = useSelector(s => s.auth.email)
  const organization = useSelector(s => s.professional.organization)
  const isOpen = useSelector(s => s.ui.sideBarOpen)

  return (
    <TransitionGroup>
      <>
        <Transition in={isOpen} timeout={TIMEOUT_MS} unmountOnExit mountOnEnter>
          {state => <Dimmer onClick={dispatchToggleSideBar} state={state} />}
        </Transition>

        <Transition in={isOpen} timeout={TIMEOUT_MS} unmountOnExit mountOnEnter>
          {state => (
            <SideBarBackground state={state}>
              <SidebarMenu>
                {hasPatient && (
                  <IconTextButton
                    label={t`Vaihda potilas`}
                    onClick={() => {
                      goToMenu()
                      dispatchToggleSideBar()
                    }}
                    icon={<SwitchPatientIcon />}
                  />
                )}
              </SidebarMenu>
              <BottomContainer>
                <ProfessionalInfo>
                  <AccentHeading1>{email}</AccentHeading1>
                  <AccentHeading2>{organization ? getValue(organization) : ''}</AccentHeading2>
                </ProfessionalInfo>
                {/* TODO: COMMENTED OUT TEMPORARILY 
                <IconTextButton
                  accent
                  label={t`Asetukset`}
                  onClick={() => {
                    goToSettings()
                    dispatchToggleSideBar()
                  }}
                  icon={<SettingsIcon />}
                /> */}
                <IconTextButton accent label={t`Kirjaudu ulos`} onClick={dispatchSignOut} icon={<LogOutIcon />} />
              </BottomContainer>
            </SideBarBackground>
          )}
        </Transition>
      </>
    </TransitionGroup>
  )
}

const ProfessionalInfo = styled.div`
  padding: 0px 0px 48px 0px;
`

const BottomContainer = styled.div`
  display: inline-block;
  align-self: stretch;
  flex: 0 1;
  background-color: ${props => props.theme.color.accent700};
  padding: ${props => props.theme.spacing.large};
`

const SideBarBackground = styled.header`
  z-index: 999;
  background-color: ${props => props.theme.color.white};
  width: 288px;

  position: absolute;
  top: 48px; // Height of top bar
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  transition: transform ${TIMEOUT_MS}ms;
  transform: translateX(${({ state }) => (state === 'entering' || state === 'exiting' ? -100 : 0)}%);
  ${basicShadow};
`

const SidebarMenu = styled.nav`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  flex: 0 1;
  gap: ${props => props.theme.spacing.regular};
  width: 100%;
`

const Dimmer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;

  transition: opacity ${TIMEOUT_MS}ms;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`

export default SideBar
