import { combineReducers } from 'redux'

import auth from './auth'
import professional from './professional'
import patient from './patient'
import question from './question'
import ui from './ui'
import settings from './settings'

const combinedReducers = combineReducers({ auth, professional, patient, question, ui, settings })

export default (state, action) => {
  let modifiedState = null
  if (action.type === 'SIGN_OUT') {
    modifiedState = {}
  }

  return combinedReducers(modifiedState ?? state, action)
}
