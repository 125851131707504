import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${p => p.flex ?? 1};
  align-items: ${p => p.alignItems ?? undefined};
  justify-content: ${p => p.justifyContent ?? undefined};
  gap: ${p => p.gap ?? 0};
`

export default Row
