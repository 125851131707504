import callBuilder from '../../utils/callBuilder'
import { reducer } from '../reducer'
import { callAction, action } from '../action'

export const getPatients = () => {
  const route = '/professional/v1/patients'
  const prefix = 'GET_PATIENTS'
  return callBuilder(route, prefix)
}

export const getPatient = (patientId, since) => {
  const route = `/professional/v1/patients/${patientId}`
  const prefix = 'GET_PATIENT'
  return callBuilder(route, prefix, undefined, undefined, { since })
}

export const clearPatient = () => ({
  type: 'CLEAR_PATIENT',
})

export const setSince = since => ({
  type: 'SET_SINCE',
  since,
})

export const createEcog = (patientId, value) => {
  const route = `/professional/v1/patients/${patientId}/ecog`
  const prefix = 'CREATE_ECOG'
  return callBuilder(route, prefix, 'POST', { value })
}

export const initialState = {
  // GET PATIENTS:
  getPatientsInitialized: false,
  getPatientsError: false,
  patients: [],
  // GET PATIENT:
  getPatientPending: false,
  getPatientError: false,
  patient: null,
  // CREATE ECOG:
  createEcogPending: false,
  createEcogError: false,
  // MISC:
  since: '2vko',
}

export default reducer(
  callAction('GET_PATIENTS', action => ({
    attempt: { getPatientsError: false },
    failure: { getPatientsInitialized: true, getPatientsError: true },
    success: { getPatientsInitialized: true, patients: action.data },
  })),
  callAction('GET_PATIENT', action => ({
    attempt: { getPatientPending: true, getPatientError: false },
    failure: { getPatientPending: false, getPatientError: true },
    success: { getPatientPending: false, patient: action.data },
  })),
  callAction('CREATE_ECOG', (action, state) => ({
    attempt: { createEcogPending: true, createEcogError: false },
    failure: { createEcogPending: false, createEcogError: true },
    success: { createEcogPending: false, patient: { ...state.patient, latestEcog: action.data } },
  })),
  action('CLEAR_PATIENT', () => ({
    patient: null,
  })),
  action('SET_SINCE', action => ({
    since: action.since,
  }))
)(initialState)
