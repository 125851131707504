import styled from 'styled-components'

const InputStyle = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px;
  height: 32px;

  background: #ffffff;

  border: 1px solid #948888;
  box-sizing: border-box;
  /* Brown shadow */

  box-shadow: 0px 2px 2px rgba(148, 136, 136, 0.25);
  border-radius: 5px;

  /* Inside Auto Layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
`

export const TextInput = styled(InputStyle)``
