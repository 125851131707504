const getRandomValues = require('get-random-values')
const AmazonCognitoIdentity = require('amazon-cognito-identity-js')

const AWS_USER_POOL_ID = 'eu-west-1_tDqSc3rtj'
const AWS_CLIENT_ID = '35tq23gmthhfd1mu79n4td5vr1'

export const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  UserPoolId: AWS_USER_POOL_ID,
  ClientId: AWS_CLIENT_ID,
})

const getRandomString = n => {
  const randomValues = new Uint8Array(n)
  getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('')
}

const intToHex = nr => {
  return nr.toString(16).padStart(2, '0')
}

class Auth {
  constructor() {
    this.user = userPool.getCurrentUser()
    this.email = null
  }

  _setUser = email => {
    this.user = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    })

    this.email = email
  }

  _getUser = email => {
    if (!this.user) {
      this._setUser(email)
    }

    return this.user
  }

  signUp = async email => {
    // Cognito requires a password. Since we use passwordless login,
    // we need to generate a secure, random password for the user.
    const password = getRandomString(30)

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'email',
      Value: email,
    })

    return new Promise((resolve, reject) => {
      userPool.signUp(email, password, [attributeEmail], null, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  signIn = async email => {
    return new Promise((resolve, reject) => {
      const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: email,
      })

      this._setUser(email)
      const cognitoUser = this._getUser(email)

      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

      cognitoUser.initiateAuth(authDetails, {
        onFailure: err => {
          reject(err)
        },
        customChallenge: () => {
          resolve(true)
        },
      })
    })
  }

  repondToChallenge = async response => {
    const cognitoUser = this._getUser()

    return new Promise((resolve, reject) => {
      cognitoUser.sendCustomChallengeAnswer(response, {
        onSuccess: sess => {
          const idToken = sess.getIdToken().getJwtToken()
          const refreshToken = sess.getRefreshToken().getToken()
          const accessToken = sess.getAccessToken().getJwtToken()
          resolve({ idToken, refreshToken, accessToken })
        },
        onFailure: err => {
          reject(err)
        },
        customChallenge: () => {
          reject(new Error('Custom challenge'))
        },
      })
    })
  }

  refresh = async (email, refreshToken) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._getUser(email)

      const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
      })

      cognitoUser.refreshSession(cognitoRefreshToken, (err, auth) => {
        if (err) {
          return reject(err)
        }

        return resolve({
          idToken: auth.getIdToken().getJwtToken(),
        })
      })
    })
  }
}

export const authInstance = new Auth()
