import { getDefaultLang } from './general'

export const parseDate = (time, locale = null) => {
  const date = new Date(time)

  const lang = getDefaultLang(locale)

  return {
    time: date.toLocaleTimeString([lang], { hour: '2-digit', minute: '2-digit' }),
    date: date.toLocaleDateString([lang]),
    dateNoYear: date.toLocaleDateString([lang], { day: '2-digit', month: '2-digit' }),
  }
}

export const parseDateKey = time => {
  const date = new Date(time)

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const strIncludes = (a, b) => {
  if (!a || !b) return false

  return a.trim().toLowerCase().includes(b.trim().toLowerCase())
}

export const compareDates = (a, b) => {
  return new Date(a).getTime() - new Date(b).getTime()
}

export const getDatesBetween = (startDate, endDate, includeEndDate) => {
  const dates = []
  const currentDate = startDate
  while (currentDate < endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  if (includeEndDate) dates.push(endDate)
  return dates
}
