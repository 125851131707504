import React from 'react'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import Card from '../common/Card'
import Column from '../common/Column'
import Row from '../common/Row'
import { Heading3, Heading2 } from '../common/typography'
import { Button } from '../common/buttons'
import Spacing from '../common/Spacing'
import Padding from '../common/Padding'
import { useGoTo } from '../../utils/hooks'
import { getSexValue } from '../../utils/general'

const ProfileCard = styled(Card)`
  flex-grow: 0;
  margin-bottom: ${p => p.theme.spacing.large};

  :last-child {
    margin-bottom: 0;
  }
`

const ProfileField = ({ title = '', value = '', ...p }) => {
  return (
    <Column {...p}>
      <Heading3>{title}</Heading3>
      <Spacing dir="y" />
      <Heading2>{value}</Heading2>
    </Column>
  )
}

const PatientProfile = ({ profile, code }) => {
  const goToPatient = useGoTo(`/patients/${profile?.userId}`)

  return (
    <ProfileCard hideHeader>
      <Padding all={8}>
        <ProfileCardRow>
          <ProfileInfoRow>
            <ProfileField title={t`Koodi`} value={`${code ?? 'Unknown'}`} flex={1.5} />
            <ProfileField title={t`Sukupuoli`} value={getSexValue(profile.sex)} />
            <ProfileField title={t`Syntymävuosi`} value={profile.birthYear} />
          </ProfileInfoRow>
          <ButtonContainer>
            <Button label={t`Tarkastele`} onClick={goToPatient} />
          </ButtonContainer>
        </ProfileCardRow>
      </Padding>
    </ProfileCard>
  )
}

const ProfileCardRow = styled(Row)`
  gap: ${props => props.theme.spacing.large};
  justify-content: center;
  flex-wrap: wrap;
`

const ProfileInfoRow = styled(Row)`
  gap: ${props => props.theme.spacing.regular};
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default PatientProfile
