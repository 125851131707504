import React from 'react'
import styled from 'styled-components'
import Row from './Row'
import { BodyText, BoldBodyText } from './typography'

const CapsuleContainer = styled(Row)`
  background-color: ${p => (p.danger ? p.theme.color.primary500 : p.theme.color.accent400)};
  border-radius: ${p => p.theme.spacing.small};
  border: 2px solid ${p => (p.danger ? p.theme.color.primary500 : p.theme.color.accent400)};
  box-sizing: border-box;

  max-width: fit-content;
`

const Hat = styled.div`
  background-color: ${p => p.color ?? (p.danger ? p.theme.color.primary500 : p.theme.color.accent400)};
  border-radius: ${p => p.theme.spacing.small} 0 0 ${p => p.theme.spacing.small};
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.regular};
`

const Capsule = ({ leftText, rightText, danger = false, ...p }) => {
  return (
    <CapsuleContainer danger={danger} {...p}>
      <Hat color="white">
        <BodyText style={{ whiteSpace: 'nowrap' }}>{leftText}</BodyText>
      </Hat>
      <Hat danger={danger}>
        <BoldBodyText style={{ color: 'white', whiteSpace: 'nowrap' }}>{rightText}</BoldBodyText>
      </Hat>
    </CapsuleContainer>
  )
}

export default Capsule
