export const callAction = (prefix, caseBuilder) => {
  const cases = [`${prefix}_ATTEMPT`, `${prefix}_FAILURE`, `${prefix}_SUCCESS`]

  return (state, action) => {
    if (!cases.includes(action.type)) return false

    const { attempt = {}, failure = {}, success = {} } = caseBuilder(action, state)

    switch (action.type) {
      case `${prefix}_ATTEMPT`:
        return { ...state, ...attempt }

      case `${prefix}_FAILURE`:
        return { ...state, ...failure }

      case `${prefix}_SUCCESS`:
        return { ...state, ...success }

      default:
        return false
    }
  }
}

export const action = (type, stateBuilder) => (state, action) => {
  if (type !== action.type) return false

  const newState = stateBuilder(action, state)
  return { ...state, ...newState }
}
