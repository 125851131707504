import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Login from '../Auth/Login'
import Info from '../Info'
import Patients from '../Patients'
import Patient from '../Patient'
// import Settings from '../Settings' // TODO: COMMENTED OUT TEMPORARILY
import TopBar from '../navigation/TopBar'
import SideBar from '../navigation/SideBar'

const PrivateRoute = ({ component: Component, authed, to = '/login', ...rest }) => {
  return (
    <Route
      {...rest}
      component={props =>
        authed === true ? <Component {...props} /> : <Redirect to={{ pathname: to, state: { from: props.location } }} />
      }
    />
  )
}

const Router = () => {
  const idToken = useSelector(s => s.auth.idToken)

  const isAuthenticated = !!idToken
  return (
    <>
      {isAuthenticated && <TopBar />}
      {isAuthenticated && <SideBar />}
      <Switch>
        <PrivateRoute exact path="/login" authed={!isAuthenticated} to="/" component={Login} />
        <PrivateRoute exact path="/" authed={isAuthenticated} component={Patients} />
        <PrivateRoute exact path="/patients/:id" authed={isAuthenticated} component={Patient} />
        {/* TODO: COMMENTED OUT TEMPORARILY <PrivateRoute exact path="/settings" authed={isAuthenticated} component={Settings} /> */}
        <Route exact path="/info" component={Info} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </>
  )
}

export default Router
