export const reducer =
  (...actionHandlers) =>
  initialState =>
  (state = { ...initialState }, action) => {
    for (let i = 0; i < actionHandlers.length; i++) {
      const handler = actionHandlers[i]
      const updatedState = handler(state, action)
      if (updatedState) return updatedState
    }

    return state
  }
