import { reducer } from '../reducer'
import { action } from '../action'

export const setLang = lang => ({
  type: 'SET_LANG',
  data: lang,
})

export const initialState = {
  lang: 'fi',
}

export default reducer(
  action('SET_LANG', action => ({
    lang: action.data,
  }))
)(initialState)
