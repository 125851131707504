import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import { FlexCard } from '../common/Card'
import { Heading3, Heading4 } from '../common/typography'
import Spacing from '../common/Spacing'
import { getSexValue } from '../../utils/general'

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 148px 64px 64px;
  grid-template-rows: repeat(2, 1fr);
  column-gap: ${props => props.theme.spacing.xLarge};
  padding-bottom: ${props => props.theme.spacing.regular};
`

const Info = () => {
  const patientProfile = useSelector(s => s.patient.patient.profile)
  const patientCode = useSelector(s => s.patient.patient.code)
  const { sex, birthYear } = patientProfile

  return (
    <FlexCard title={t`Perustiedot`}>
      <Spacing dir="y" />
      <InfoGrid>
        <Heading4>{t`Koodi`}</Heading4>
        <Heading4>{t`Sukupuoli`}</Heading4>
        <Heading4>{t`Syntymävuosi`}</Heading4>

        <InfoText>{patientCode ?? 'Unknown'}</InfoText>
        <InfoText>{getSexValue(sex)}</InfoText>
        <InfoText>{birthYear}</InfoText>
      </InfoGrid>
    </FlexCard>
  )
}

const InfoText = styled(Heading3)`
  color: ${props => props.theme.color.grey900};
`

export default Info
