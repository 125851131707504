import { t } from '@lingui/macro'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bar, CartesianAxis, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'

import { getLocalTimeZone, today } from '@internationalized/date'
import { useTheme } from '../../utils/hooks'
import { makeBarData, makeHighlightData } from '../../utils/steps'
import { FlexCard } from '../common/Card'
import Column from '../common/Column'
import DateRangePicker from '../common/DateRangePicker'
import Padding from '../common/Padding'
import Row from '../common/Row'
import Spacing from '../common/Spacing'
import { basicShadowLarge } from '../common/styles'
import { BodyText, Heading3Black, Heading4, Heading4Black, NumberHighlight } from '../common/typography'

const MAX_STEPS_TO_SHOW = 15000

const Steps = () => {
  const theme = useTheme()
  const patientSteps = useSelector(s => s.patient.patient.steps)
  const lang = useSelector(s => s.settings.lang)

  const [dateRangePickerValue, setDateRangePickerValue] = React.useState({
    start: null,
    end: today(getLocalTimeZone()),
  })
  const patientStepsMinDate = dateRangePickerValue
    ? dateRangePickerValue.start
      ? dateRangePickerValue.start.toDate(getLocalTimeZone())
      : undefined
    : undefined

  const patientStepsMaxDate = dateRangePickerValue
    ? dateRangePickerValue.end.add({ days: 1 }).toDate(getLocalTimeZone())
    : undefined

  const filteredPatientSteps = patientSteps.filter(stepItem => {
    if (!patientStepsMinDate) {
      // No minimum set so show all answers
      return true
    }

    return (
      new Date(stepItem.steps_date).getTime() >= patientStepsMinDate.getTime() &&
      new Date(stepItem.steps_date).getTime() <= patientStepsMaxDate.getTime()
    )
  })

  const barData = makeBarData(filteredPatientSteps, MAX_STEPS_TO_SHOW, lang)
  console.log('barData', barData)
  const highlightData = makeHighlightData(filteredPatientSteps)

  const chartRef = useRef(null)
  const [chartCursorWidth, setChartCursorWidth] = useState(0)

  const onMouseMove = () => {
    setChartCursorWidth(chartRef.current.state.xAxisMap[0].width / barData.length)
  }

  return (
    <FlexCard title={t`Aktiivisuus`}>
      <Spacing dir="y" />

      <DateRangePicker
        label={t`Voit rajata näytettyjä askeleita päivämäärän mukaan alta`}
        value={dateRangePickerValue}
        onChange={setDateRangePickerValue}
        granularity="day"
        maxValue={today(getLocalTimeZone())}
        shouldForceLeadingZeros
      />
      <Spacing dir="y" amount="xLarge" />

      <Heading4>
        {t`Askeleet`} - {t`Kehitys`}
      </Heading4>
      <Spacing dir="y" amount="large" />

      <GraphContainer>
        <ResponsiveContainer height="99%" width="99%">
          <ComposedChart
            data={barData}
            ref={chartRef}
            margin={{ top: 0, right: 0, left: -8, bottom: 0 }}
            onMouseEnter={onMouseMove}
          >
            <XAxis dataKey="date" />
            <YAxis allowDataOverflow domain={[0, MAX_STEPS_TO_SHOW]} ticks={[3000, 6000, 9000, 12000, 15000]} />
            {highlightData.sum > 0 && (
              <Tooltip
                content={<YearTooltip />}
                animationDuration={500}
                cursor={{ stroke: theme.color.grey400, strokeWidth: chartCursorWidth }}
              />
            )}
            <CartesianAxis />
            <Bar
              name={t`askeleet`}
              dataKey="missing"
              fill={theme.color.grey300}
              stackId="primary"
              legendType="none"
              tooltipType="none"
              isAnimationActive={false}
              background={{ fill: theme.color.grey100 }}
            />
            <Bar name={t`askeleet`} dataKey="steps" fill={theme.color.primary400} stackId="primary" />
            <Line
              type="natural"
              dataKey="trendSteps"
              stroke={theme.color.primary900}
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphContainer>
      <Spacing dir="y" />

      {filteredPatientSteps.length ? (
        <Row flex={0}>
          <Highlight title={`${t`Askeleet`} - ${t`Keskiarvo`}`} value={highlightData.avg} />
          <Highlight title={`${t`Askeleet`} - ${t`Mediaani`}`} value={highlightData.median} />
          <Highlight title={`${t`Askeleet`} - ${t`Maksimi`}`} value={highlightData.max} />
          <Highlight title={`${t`Askeleet`} - ${t`Minimi`}`} value={highlightData.min} />
        </Row>
      ) : (
        <>
          <Heading3Black>{t`Ei dataa`}</Heading3Black>
          <BodyText>{t`Valitsemallasi tarkasteluvälillä ei löytynyt yhtään päivää, jolta olisi mitattu askelia. Voit muuttaa tarkasteluväliä valintapainikkeilla.`}</BodyText>
        </>
      )}
      <Spacing dir="y" amount="xLarge" />
    </FlexCard>
  )
}

const GraphContainer = styled.div`
  flex: 1;
  min-height: 160px;
`

const TooltipColumn = styled(Column)`
  opacity: 1;
  border-radius: ${p => p.theme.spacing.small} ${p => p.theme.spacing.small};
  background-color: ${p => p.theme.color.white};
  ${basicShadowLarge}
`

const Highlight = ({ title, value }) => {
  return (
    <Column flex={0.25}>
      <Heading4 style={{ whiteSpace: 'nowrap' }}>{title}</Heading4>
      <Spacing dir="y" />
      {value || value === 0 ? (
        <>
          <NumberHighlight>{Math.round(value)}</NumberHighlight>
          <Heading4Black>{t`Askelta`}</Heading4Black>
        </>
      ) : (
        <NumberHighlight>{t`Ei dataa`}</NumberHighlight>
      )}
    </Column>
  )
}

const YearTooltip = ({ active, payload }) => {
  if (active && payload && payload[0] && payload[0].payload && payload[0].payload.steps) {
    return (
      <TooltipColumn flex={0.25}>
        <Padding t={4} b={4} l={8} r={8}>
          <Heading3Black>{payload[0].payload.dateYear}</Heading3Black>
          <NumberHighlight>{payload[0].payload.steps}</NumberHighlight>
          <BodyText>{t`Askelta`}</BodyText>
        </Padding>
      </TooltipColumn>
    )
  }

  return (
    <TooltipColumn flex={0.25}>
      <Padding t={4} b={4} l={4} r={4}>
        <Heading3Black>{t`Ei dataa`}</Heading3Black>
      </Padding>
    </TooltipColumn>
  )
}

export default Steps
