import { t } from '@lingui/macro'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { OverlayContainer, VisuallyHidden, useFocusRing, useRadio, useRadioGroup } from 'react-aria'
import { useDispatch, useSelector } from 'react-redux'
import { useRadioGroupState } from 'react-stately'

import { toast } from 'react-toastify'
import { createEcog } from '../../redux/reducers/patient'
import { parseDate } from '../../utils/date'
import { usePrev } from '../../utils/hooks'
import Column from '../common/Column'
import Modal from '../common/Modal'
import Row from '../common/Row'
import Spacing from '../common/Spacing'
import { Button } from '../common/buttons'
import { basicShadow, basicShadowLarge, basicShadowSmall } from '../common/styles'
import { BodyText, Heading2, Heading3 } from '../common/typography'

const RadioContext = React.createContext(null)

const EvaluationDialog = ({ onClose }) => {
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = React.useState(undefined)

  const currentPatient = useSelector(s => s.patient?.patient)
  const createEcogPending = useSelector(s => s.patient?.createEcogPending)
  const createEcogError = useSelector(s => s.patient?.createEcogError)
  const prevCreateEcogPending = usePrev(createEcogPending)

  const onSaveEval = useCallback(() => {
    dispatch(createEcog(currentPatient.profile.userId, selectedValue))
  }, [dispatch, selectedValue, currentPatient])

  useEffect(() => {
    if (prevCreateEcogPending && !createEcogPending) {
      if (createEcogError) {
        toast.error(t`ECOG-luokituksen tallentaminen epäonnistui`)
      } else {
        toast.success(t`ECOG-luokitus (ECOG-luokka: ${selectedValue}) tallennettu onnistuneesti`)
        onClose()
      }
    }
  }, [createEcogPending])

  return (
    <OverlayContainer>
      <Modal title={t`Potilaan toimintakyvyn arviointi`} isOpen onClose={onClose} isDismissable>
        <Column>
          <SubHeading>{t`ECOG-toimintakykyluokitus`}</SubHeading>
          <Spacing dir="y" />

          <BodyText>{t`Valitse potilaalle toimintakykyä vastaava ECOG-luokka`}</BodyText>
          <Spacing dir="y" amount="large" />

          <ScoreGroup label={t`ECOG-luokka`} updateSelected={setSelectedValue}>
            <ScoreItem value="0">0</ScoreItem>
            <ScoreItem value="1">1</ScoreItem>
            <ScoreItem value="2">2</ScoreItem>
            <ScoreItem value="3">3</ScoreItem>
            <ScoreItem value="4">4</ScoreItem>
          </ScoreGroup>
          <LatestEcog latestEcog={currentPatient?.latestEcog} />
          <EcogDescription selected={selectedValue} />
          <Spacing dir="y" amount="xLarge" />

          <SaveButton label={t`Tallenna arvio`} onClick={onSaveEval} disabled={!selectedValue || createEcogPending} />
        </Column>
      </Modal>
    </OverlayContainer>
  )
}

const EcogDescription = ({ selected }) => {
  const descriptions = [
    { value: 0, description: t`Täysin omatoiminen, ei mitään toimintarajoitteita` },
    {
      value: 1,
      description: t`Omatoiminen ja pystyy hoitamaan itsensä, mutta ei
    kykene raskaisiin suorituksiin`,
    },
    {
      value: 2,
      description: t`Pystyy hoitamaan itsensä, mutta ei kykene mihinkään
    suorituksiin. Pystyssä yli puolet valveillaoloajasta`,
    },
    {
      value: 3,
      description: t`Tarvitsee apua päivittäisissä toiminnoissa. Vuoteessa tai
    lepotuolissa yli puolet valveillaoloajasta`,
    },
    { value: 4, description: t`Täysin ulkoisen hoivan varassa` },
  ]

  return (
    <>
      <Spacing dir="y" />

      <Heading3>THL, 2013:</Heading3>
      {descriptions.map(d => (
        <React.Fragment key={d.value}>
          {d.value === Number(selected) ? (
            <Heading3 style={{ color: 'black' }}>
              {d.value}: {d.description}
            </Heading3>
          ) : (
            <Heading3>
              {d.value}: {d.description}
            </Heading3>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

const LatestEcog = ({ latestEcog }) => {
  if (!latestEcog) return null

  const now = parseDate(new Date())
  const createdAt = parseDate(latestEcog.createdAt)

  return (
    <>
      <Spacing dir="y" />
      {now.date !== createdAt.date ? (
        <Heading3>
          {t`Viimeisin ECOG-arvio annettu`} {createdAt.date}.
        </Heading3>
      ) : (
        <Heading3>
          {t`Viimeisin ECOG-arvio`}: {latestEcog.value}, {t`Tänään klo`} {createdAt.time}
        </Heading3>
      )}
    </>
  )
}

const ScoreGroup = props => {
  const radioGroupState = useRadioGroupState(props)
  const { children, label, updateSelected } = props
  const { radioGroupProps, labelProps } = useRadioGroup(props, radioGroupState)

  React.useEffect(() => updateSelected(radioGroupState.selectedValue), [radioGroupState.selectedValue]) // TODO: Can this stupidity be avoided...

  return (
    <EvalSelectRow {...radioGroupProps}>
      <VisuallyHidden>
        <span {...labelProps}>{label}</span>
      </VisuallyHidden>
      <RadioContext.Provider value={radioGroupState}>{children}</RadioContext.Provider>
    </EvalSelectRow>
  )
}

const ScoreItem = props => {
  const { value, children } = props
  const state = React.useContext(RadioContext)
  const ref = React.useRef(null)
  const { inputProps } = useRadio(props, state, ref)

  const { isFocusVisible, focusProps } = useFocusRing()

  const isSelected = state.selectedValue === value

  return (
    <ScoreButton aria-hidden="true" isSelected={isSelected} isFocusVisible={isFocusVisible}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      {children}
    </ScoreButton>
  )
}

const SubHeading = styled(Heading2)`
  color: ${props => props.theme.color.grey600};
`

const EvalSelectRow = styled(Row)`
  gap: ${props => props.theme.spacing.regular};
  align-items: flex-start;
  flex: 0;
`

const ScoreButton = styled.label`
  ${props => props.theme.font.accent.extraBold};
  ${basicShadow}

  height: 32px;
  width: 64px;

  background-color: ${props => (props.isSelected ? props.theme.color.accent600 : props.theme.color.white)};
  color: ${props => (props.isSelected ? props.theme.color.white : props.theme.color.accent600)};

  border-radius: ${props => props.theme.spacing.small};
  border: 2px solid ${props => (props.isFocusVisible ? props.theme.color.accent600 : props.theme.color.accent600)}; // A11Y TODO: Decide focus ring color

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 250ms, box-shadow 250ms, opacity 300ms;

  :hover {
    ${basicShadowLarge};
    opacity: 0.9;
    cursor: pointer;
  }

  :active {
    transform: scale(0.98);
    ${basicShadowSmall}
  }
`

const SaveButton = styled(Button)`
  align-self: flex-end;
`

export default EvaluationDialog
