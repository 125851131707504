import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from '@lingui/macro'

import { activateAccessCode } from '../../redux/reducers/professional'
import { signOut } from '../../redux/reducers/auth'
import { useInput, usePrev } from '../../utils/hooks'
import Centered from '../common/Centered'
import Form from '../common/Form'
import Spacing from '../common/Spacing'
import PageContainer from '../common/PageContainer'
import Row from '../common/Row'
import Card from '../common/Card'
import Fill from '../common/Fill'
import Padding from '../common/Padding'
import { Button, TextButton } from '../common/buttons'
import { Heading2, Heading3, BodyText } from '../common/typography'
import { TextInput } from '../common/inputs'
import EvexiaLogoRedWithTitle from '../../assets/EvexiaLogoRedWithTitle.svg'

const AccessCodeContainer = styled(Padding)`
  display: flex;
  flex-direction: column;
`

const AccessCode = () => {
  const dispatch = useDispatch()
  const [accessCode, setAccessCode] = useInput('')

  const activateAccessCodePending = useSelector(s => s.professional.activateAccessCodePending)
  const activateAccessCodeError = useSelector(s => s.professional.activateAccessCodeError)
  const prevActivateAccessCodeError = usePrev(activateAccessCodeError)

  const dispatchActivateAccessCode = useCallback(() => {
    dispatch(activateAccessCode(accessCode))
  }, [dispatch, accessCode])

  const dispatchSignOut = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  useEffect(() => {
    if (!prevActivateAccessCodeError && activateAccessCodeError) {
      alert('Virheellinen käyttökoodi')
    }
  }, [activateAccessCodeError])

  return (
    <PageContainer>
      <Row>
        <Fill />
        <Form>
          <Spacing dir="y" amount="xLarge" />
          <Card style={{ maxWidth: 350 }} hideHeader>
            <AccessCodeContainer all={16}>
              <Centered>
                <EvexiaLogoRedWithTitle />
              </Centered>
              <Spacing dir="y" amount="xLarge" />
              <Heading3>{t`Evexia - Ammattilaisen käyttöliittymä`}</Heading3>
              <Spacing dir="y" />
              <Heading2>{t`Tilin aktivointi`}</Heading2>
              <Spacing dir="y" />
              <BodyText>{t`Syötä saamasi käyttökoodi`}</BodyText>
              <Spacing dir="y" />
              <BodyText>{t`Käyttökoodi mahdollistaa sovelluksen käytön.`}</BodyText>
              <Spacing dir="y" />
              <TextInput placeholder={t`Käyttökoodi`} value={accessCode} onChange={setAccessCode} />
              <Spacing dir="y" />
              <Button disabled={activateAccessCodePending} label={t`Aktivoi`} onClick={dispatchActivateAccessCode} />
              <Spacing dir="y" />
              <TextButton label={t`Kirjaudu ulos`} onClick={dispatchSignOut} />
            </AccessCodeContainer>
          </Card>
        </Form>
        <Fill />
      </Row>
    </PageContainer>
  )
}

export default AccessCode
