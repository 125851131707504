import styled from 'styled-components'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${p => p.flex ?? 1};
  align-items: ${p => p.alignItems ?? undefined};
  justify-content: ${p => p.justifyContent ?? undefined};
  align-self: ${p => p.alignSelf ?? undefined};
`

export default Column
