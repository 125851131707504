import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import localforage from 'localforage'
import thunk from 'redux-thunk'

import combinedReducers from './reducers'
import { initialState as authInitialState } from './reducers/auth'

import { authMiddleware } from '../utils/callBuilder'

const AuthTransform = createTransform(
  inboundState => ({ ...inboundState }),
  outboundState => ({
    ...authInitialState,
    idToken: outboundState?.idToken ?? null,
    email: outboundState?.email ?? null,
  }),
  { whitelist: ['auth'] }
)

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['auth', 'settings'],
  transforms: [AuthTransform],
}

const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = createStore(persistedReducer, compose(applyMiddleware(thunk, authMiddleware)))
export const persistor = persistStore(store)
