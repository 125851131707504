import { getLocalTimeZone, today } from '@internationalized/date'
import { t } from '@lingui/macro'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import question from '../../redux/reducers/question'
import { useGetValue } from '../../utils/hooks'
import Capsule from '../common/Capsule'
import Card from '../common/Card'
import Column from '../common/Column'
import DateRangePicker from '../common/DateRangePicker'
import Row from '../common/Row'
import Spacing from '../common/Spacing'
import { TextButton } from '../common/buttons'
import { BodyText } from '../common/typography'

const QuestionsColumn = styled(Column)`
  align-items: flex-start;
  justify-content: space-between;
  gap: ${p => p.theme.spacing.large};

  padding-bottom: ${p => p.theme.spacing.large};
`

const QuestionRow = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
  gap: ${p => p.theme.spacing.xLarge};

  width: 100%;
`

const QuestionAnswersRow = styled(Row)`
  align-items: flex-start;
  justify-content: flex-end;
  gap: ${p => p.theme.spacing.small};
  flex-wrap: wrap;
`

const QuestionCategory = ({
  id,
  title,
  totalAnswers,
  highlights,
  questions,
  answerHighlightSet,
  answerMap,
  openCategory,
  isOpen,
}) => {
  const getValue = useGetValue()

  const handleClick = useCallback(() => {
    openCategory(id, !isOpen)
  }, [openCategory, id, isOpen])

  const maxAnswersPerQuestion = useMemo(() => {
    return questions.reduce((res, question) => {
      res[question.id] = 0
      question.answers.forEach(answer => {
        res[question.id] = Math.max(res[question.id], answerMap[answer.id]?.length ?? 0)
      })
      return res
    }, {})
  }, [questions, answerMap])

  useEffect(() => {
    openCategory(id, false)
  }, [])

  return (
    <>
      <Card
        title={title}
        info={totalAnswers === 1 ? t`1 vastattu kysymys` : `${totalAnswers} ${t`vastattua kysymystä`}`}
        highlight={
          highlights === 0
            ? null
            : highlights === 1
            ? t`1 korostettu vastaus`
            : `${highlights} ${t`korostettua vastausta`}`
        }
        collapsible
        open={isOpen}
        onClick={handleClick}
      >
        <Spacing dir="y" />

        <QuestionsColumn>
          {questions.map(q => (
            <QuestionRow key={q.id}>
              <BodyText>{getValue(q.value)}</BodyText>
              <QuestionAnswersRow>
                {q.answers.map(answer => (
                  <Capsule
                    danger={
                      answerMap[answer.id]?.length === maxAnswersPerQuestion[q.id] && answerHighlightSet.has(answer.id)
                    }
                    key={answer.id}
                    leftText={getValue(answer.value)}
                    rightText={`x${answerMap[answer.id]?.length ?? 0}`}
                  />
                ))}
              </QuestionAnswersRow>
            </QuestionRow>
          ))}
        </QuestionsColumn>
      </Card>
      <Spacing dir="y" amount="large" />
    </>
  )
}

const QuestionAnswers = () => {
  const [dateRangePickerValue, setDateRangePickerValue] = React.useState({
    start: null,
    end: today(getLocalTimeZone()),
  })
  const patientAnswersMinDate = dateRangePickerValue
    ? dateRangePickerValue.start
      ? dateRangePickerValue.start.toDate(getLocalTimeZone())
      : undefined
    : undefined

  const patientAnswersMaxDate = dateRangePickerValue
    ? dateRangePickerValue.end.add({ days: 1 }).toDate(getLocalTimeZone())
    : undefined

  const getValue = useGetValue()
  const [openedCategories, setOpenedCategories] = useState({})
  const patientAnswers = useSelector(s => s.patient.patient.answers)
  const questions = useSelector(s => s.question.questions)
  const answerHighlights = useSelector(s => s.question.answerHighlights)

  const filteredPatientAnswers = patientAnswers.filter(answer => {
    if (!patientAnswersMinDate) {
      // No minimum set so show all answers
      return true
    }

    return (
      new Date(answer.updatedAt).getTime() >= patientAnswersMinDate.getTime() &&
      new Date(answer.updatedAt).getTime() < patientAnswersMaxDate.getTime()
    )
  })

  const [answerMap, categoryMeta, answerHighlightSet] = useMemo(() => {
    const answerMap = filteredPatientAnswers.reduce((acc, curr) => {
      if (!acc[curr.answerId]) acc[curr.answerId] = []
      acc[curr.answerId].push(curr)
      return acc
    }, {})

    const answerHighlightSet = new Set(answerHighlights?.map(aH => aH.answerId))

    const categoryMeta = questions.reduce((acc, curr) => {
      let total = 0
      let highlights = 0
      curr.questions.forEach(q => {
        let topAnswerId = null
        let topAnswers = -Infinity
        q.answers.forEach(a => {
          const amountOfAnswers = answerMap[a.id]?.length ?? 0
          total += amountOfAnswers
          if (amountOfAnswers > topAnswers || (amountOfAnswers === topAnswers && answerHighlightSet.has(a.id))) {
            topAnswerId = a.id
            topAnswers = amountOfAnswers
          }
        })
        if (topAnswers > 0 && answerHighlightSet.has(topAnswerId)) highlights += 1
      })
      acc[curr.id] = { total, highlights }
      return acc
    }, {})

    return [answerMap, categoryMeta, answerHighlightSet]
  }, [filteredPatientAnswers, question, answerHighlights])

  const openCategory = useCallback(
    (categoryId, isOpen) => {
      setOpenedCategories(prevState => {
        const newState = Object.assign(openedCategories, { [categoryId]: isOpen })
        return { ...prevState, ...newState }
      })
    },
    [openedCategories]
  )

  const openAllCategories = useCallback(() => {
    const allOpen = Object.keys(openedCategories).reduce((res, curr) => {
      res[curr] = true
      return res
    }, {})

    setOpenedCategories(allOpen)
  }, [openedCategories])

  const closeAllCategories = useCallback(() => {
    const allClosed = Object.keys(openedCategories).reduce((res, curr) => {
      res[curr] = false
      return res
    }, {})

    setOpenedCategories(allClosed)
  }, [openedCategories])

  const isOneCategoryOpen = !!Object.values(openedCategories).find(v => v)
  return (
    <div>
      <div>
        <DateRangePicker
          label={t`Voit rajata kysymysvastauksia päivämäärän mukaan alta.`}
          value={dateRangePickerValue}
          onChange={setDateRangePickerValue}
          granularity="day"
          maxValue={today(getLocalTimeZone())}
          shouldForceLeadingZeros
        />
      </div>
      <Row justifyContent="flex-end">
        {isOneCategoryOpen ? (
          <TextButton label={t`Sulje kaikki`} onClick={closeAllCategories} />
        ) : (
          <TextButton label={t`Avaa kaikki`} onClick={openAllCategories} />
        )}
      </Row>
      {questions.map(category => (
        <QuestionCategory
          key={category.id}
          id={category.id}
          title={getValue(category.value)}
          totalAnswers={categoryMeta[category.id].total}
          highlights={categoryMeta[category.id].highlights}
          questions={category.questions}
          answerHighlightSet={answerHighlightSet}
          answerMap={answerMap}
          isOpen={openedCategories[category.id]}
          openCategory={openCategory}
        />
      ))}
    </div>
  )
}

export default QuestionAnswers
