import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'

import { getHasAccess } from '../../redux/reducers/professional'
import AccessCode from '../AccessCode'

const authPaths = new Set(['/login'])

const AuthGate = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location

  const hasAccess = useSelector(s => s.professional.hasAccess)
  const hasAccessInitialized = useSelector(s => s.professional.hasAccessInitialized)
  const idToken = useSelector(s => s.auth.idToken)

  useEffect(() => {
    if (idToken) {
      dispatch(getHasAccess())
    }
  }, [idToken])

  if (!hasAccessInitialized && !!idToken) return <p>{t`Ladataan...`}</p>
  if (!hasAccess && !authPaths.has(pathname) && !!idToken) return <AccessCode />

  return children
}

export default AuthGate
