import React from 'react'
import styled from 'styled-components'
import Column from './Column'

const PageColumn = styled(Column)`
  flex: 1;
  align-self: stretch;
  padding: ${props => props.theme.spacing.large};
  overflow-y: auto;
`

const PageContainer = ({ children }) => {
  return <PageColumn>{children}</PageColumn>
}

export default PageContainer
