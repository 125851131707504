import { t } from '@lingui/macro'

export const sleep = async ms => {
  return new Promise(res => {
    setTimeout(res, ms)
  })
}

const SUPPORTED_LANGUAGES = new Set(['fi', 'en'])

export const getDefaultLang = (locale = null) => {
  try {
    const defaultLang = locale ?? Intl.NumberFormat().resolvedOptions().locale ?? 'en'

    if (SUPPORTED_LANGUAGES.has(defaultLang)) {
      return defaultLang
    }
  } catch (e) {
    return 'en'
  }

  return 'en'
}

export const strIncludes = (a, b) => {
  if (!a || !b) return false

  return a.trim().toLowerCase().includes(b.trim().toLowerCase())
}

export const round = (x, decimals) => {
  return Math.round((x + Number.EPSILON) * 10 ** decimals) / 10 ** decimals
}

export const withConfirm = (msg, f) => () => {
  const confirmed = window.confirm(msg)

  if (confirmed) f()
}

export const getSexValue = value => {
  if (value === 'male') return t`Mies`
  if (value === 'female') return t`Nainen`

  return t`Muu`
}

export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
