import { t } from '@lingui/macro'
import React from 'react'
import styled from 'styled-components'
import { openInNewTab } from '../../utils/general'
import { Button } from '../common/buttons'
import Card from '../common/Card'
import Column from '../common/Column'
import Spacing from '../common/Spacing'
import { BodyText, Heading1 } from '../common/typography'

const FORM_URL = 'https://forms.gle/2CznwbE4rrewNK3UA'

const SurveyCardContainer = styled(Card)`
  background-color: ${p => p.theme.color.primary300};
`

const SurveyCard = () => {
  return (
    <SurveyCardContainer hideHeader>
      <Spacing dir="y" />
      <Column alignItems="center">
        <Heading1>{t`Hei!`}</Heading1>
        <div>
          <Spacing dir="y" />
          <BodyText>
            {t`Vastauksenne käytettävyyskyselyyn on meille tärkeää. Kysely vie noin 5-10 minuuttia aikaa. Alla oleva nappi vie teidät kyselyyn. Kiitos!`}
          </BodyText>
        </div>
        <Spacing dir="y" />
        <div>
          <Button
            label={t`Vastaa kyselyyn`}
            onClick={() => {
              openInNewTab(FORM_URL)
            }}
          />
        </div>
      </Column>
      <Spacing dir="y" />
      <Spacing dir="y" />
    </SurveyCardContainer>
  )
}

export default SurveyCard
