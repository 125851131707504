const theme = {
  /* COLORS */
  color: {
    grey900: '#2A2222',
    grey800: '#3F3535',
    grey700: '#564A4A',
    grey600: '#958787',
    grey500: '#BCAEAE',
    grey400: '#D9CFCF',
    grey300: '#E6DFDF',
    grey200: '#EFE9E9',
    grey100: '#F4F0F0',
    grey50: '#F9F7F7',
    primary900: '#AD0403',
    primary800: '#C51D1C',
    primary700: '#DD2624',
    primary600: '#EB403E',
    primary500: '#F95A59',
    primary400: '#FC7372',
    primary300: '#FEB2B1',
    primary200: '#FDD7D7',
    primary100: '#FFF5F4',
    accent900: '#172838',
    accent800: '#25496E',
    accent700: '#34689D',
    accent600: '#3872AD',
    accent500: '#4583C3',
    accent400: '#558FCA',
    accent300: '#6FA1D3',
    accent200: '#B1D3F2',
    accent100: '#F0F8FE',
    white: 'white',
    error: '#CD2E2D',
    warning: '#FC9503',
  },

  /* FONT FAMILIES */
  font: {
    accent: {
      light: "font-family: 'Nunito', sans-serif; font-weight: 300",
      regular: "font-family: 'Nunito', sans-serif; font-weight: 400",
      medium: "font-family: 'Nunito', sans-serif; font-weight: 600",
      bold: "font-family: 'Nunito', sans-serif; font-weight: 700;",
      extraBold: "font-family: 'Nunito', sans-serif; font-weight: 800",
      black: "font-family: 'Nunito', sans-serif; font-weight: 900",
    },
    body: {
      light: "font-family: 'Roboto', sans-serif; font-weight: 300",
      regular: "font-family: 'Roboto', sans-serif; font-weight: 400",
      medium: "font-family: 'Roboto', sans-serif; font-weight: 500",
      bold: "font-family: 'Roboto', sans-serif; font-weight: 700",
      // extraBold: "",
      black: "font-family: 'Roboto', sans-serif; font-weight: 900",
    },

    /* FONT SIZES */
    fontSize: {
      xxSmall: '8px',
      xSmall: '0.625rem',
      small: '0.75rem',
      regular: '0.875rem',
      large: '1rem',
      xLarge: '1.125rem',
      xxLarge: '1.25rem',
      huge: '1.5rem',
      xHuge: '1.875rem',
      xxHuge: '2.25rem',
      massive: '3rem',
      xMassive: '3.75rem',
      xxMassive: '4.5rem',
    },
  },
  spacing: {
    xxSmall: '1px',
    xSmall: '2px',
    small: '4px',
    regular: '8px',
    large: '16px',
    xLarge: '32px',
    xxLarge: '64px',
    xxxLarge: '128px',
  },
  breakpoints: {
    phone: '764px',
    tabletDown: '1179px',
    tabletUp: '1180px',
    desktop: '1800px',
  },
}

export default theme
