import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import ReactCodeInput from 'react-code-input'
import { t } from '@lingui/macro'

import Card from '../common/Card'
import Centered from '../common/Centered'
import Row from '../common/Row'
import Form from '../common/Form'
import Padding from '../common/Padding'
import PageContainer from '../common/PageContainer'
import Spacing from '../common/Spacing'
import { Heading3, BodyText, Heading1 } from '../common/typography'
import { TextInput } from '../common/inputs'
import { Button, TextButton } from '../common/buttons'
import { useInput, usePrev } from '../../utils/hooks'
import { signIn, respondToChallenge, acceptPolicies } from '../../redux/reducers/auth'
import EvexiaLogoRedWithTitle from '../../assets/EvexiaLogoRedWithTitle.svg'
import { LATEST_PRIVACY_POLICY_URL, LATEST_TERMS_URL } from '../../config'

const LoginContentContainer = styled(Padding)`
  display: flex;
  flex-direction: column;
`

const LoginPage = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useInput('')
  const [isValid, setIsValid] = useState(true)
  const [code, setCode] = useState('')
  const [mode, setMode] = useState('@login')
  const [lulToggle, setLulToggle] = useState(false)
  const codeRef = useRef()

  const signInPending = useSelector(s => s.auth.signInPending)
  const signInError = useSelector(s => s.auth.signInError)
  const signUpPending = useSelector(s => s.auth.signUpPending)
  const respondToChallengePending = useSelector(s => s.auth.respondToChallengePending)
  const respondToChallengeError = useSelector(s => s.auth.respondToChallengeError)
  const showPolicies = useSelector(s => s.auth.showPolicies)

  const prevSignInPending = usePrev(signInPending)
  const isLogin = mode === '@login'
  const isChallenge = mode === '@challenge'

  const handleLogin = useCallback(() => {
    dispatch(signIn(email))
  }, [dispatch, email])

  const backToLogin = useCallback(() => {
    setMode('@login')
  }, [])

  const dispatchAcceptPolicies = useCallback(() => {
    dispatch(acceptPolicies())
  }, [dispatch])

  useEffect(() => {
    if (!signInPending && prevSignInPending && !signInError) {
      setMode('@challenge')
    }
  }, [signInPending])

  useEffect(() => {
    if (signInError) window.alert('Virhe, tarkista sähköposti')
  }, [signInError])

  useEffect(() => {
    if (respondToChallengeError) {
      window.alert('Virhe, tarkista koodi')
      setIsValid(false)
      setLulToggle(!lulToggle)
      setCode('')
    }
  }, [respondToChallengeError])

  useEffect(() => {
    if (code.length > 0 && !isValid) setIsValid(true)
    if (code.length === 8) dispatch(respondToChallenge(code))
  }, [code])

  return (
    <PageContainer>
      <Row style={{ justifyContent: 'center' }}>
        <Form maxWidth={isChallenge ? '500px' : undefined}>
          <Spacing dir="y" amount="xLarge" />
          <Card hideHeader>
            <LoginContentContainer all={16}>
              <Centered>
                <EvexiaLogoRedWithTitle />
              </Centered>
              <Spacing dir="y" amount="xLarge" />

              <Heading3>{t`Evexia - Ammattilaisen käyttöliittymä`}</Heading3>
              <Spacing dir="y" />

              {showPolicies && (
                <>
                  <Heading1>{t`Käyttöehdot`}</Heading1>
                  <Spacing dir="y" amount="large" />

                  <BodyText>
                    {t`Hyväksytkö Evexia-sovelluksen`}{' '}
                    <a href={LATEST_TERMS_URL} target="_blank" rel="noopener noreferrer">
                      {t`käyttöehdot`}
                    </a>{' '}
                    {t`ja`}{' '}
                    <a href={LATEST_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                      {t`tietosuojakäytännön`}
                    </a>
                    ?
                  </BodyText>
                  <Spacing dir="y" amount="large" />

                  <Button disabled={signUpPending} label={t`Hyväksyn`} onClick={dispatchAcceptPolicies} />
                  <Spacing dir="y" />

                  <TextButton label={t`Takaisin`} onClick={backToLogin} />
                </>
              )}
              {isLogin && !showPolicies && (
                <>
                  <Heading1>{t`Kirjautuminen`}</Heading1>
                  <Spacing dir="y" />

                  <BodyText>{t`Kirjaudu käyttämällä työsähköpostiosoitettasi`}</BodyText>
                  <Spacing dir="y" />

                  <BodyText>{t`Saat sähköpostiisi koodin, jolla kirjaudut sisään palveluun.`}</BodyText>
                  <Spacing dir="y" />

                  <TextInput placeholder={t`Sähköposti`} value={email} onChange={setEmail} />
                  <Spacing dir="y" />

                  <Button disabled={signInPending} label={t`Seuraava`} onClick={handleLogin} />
                </>
              )}
              {isChallenge && !showPolicies && (
                <>
                  <Heading1>{t`Vahvistuskoodi`}</Heading1>
                  <Spacing dir="y" />
                  <BodyText>
                    {t`Lähetimme vahvistuskoodin`} <b>{email}</b>.
                  </BodyText>
                  <Spacing dir="y" />
                  <BodyText>{t`Syötä saamasi koodi alle.`}</BodyText>
                  <Spacing dir="y" />
                  <Centered>
                    {/* Disgusting, but only way to reset atm. See https://github.com/40818419/react-code-input/issues/69 */}
                    {lulToggle ? (
                      <ReactCodeInput
                        key="1"
                        isValid={isValid}
                        disabled={respondToChallengePending}
                        autoFocus
                        value={code}
                        onChange={setCode}
                        type="text"
                        fields={8}
                        ref={codeRef}
                      />
                    ) : (
                      <ReactCodeInput
                        key="2"
                        isValid={isValid}
                        disabled={respondToChallengePending}
                        autoFocus
                        value={code}
                        onChange={setCode}
                        type="text"
                        fields={8}
                        ref={codeRef}
                      />
                    )}
                  </Centered>
                  <Spacing dir="y" />
                  <BodyText>{t`Eikö koodi tullut perille? Tarkistithan roskapostin?`}</BodyText>
                  <Spacing dir="y" />
                  <TextButton label={t`Takaisin`} onClick={backToLogin} />
                </>
              )}
            </LoginContentContainer>
          </Card>
        </Form>
      </Row>
    </PageContainer>
  )
}

export default LoginPage
