import { t } from '@lingui/macro'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { clearPatient, getPatient } from '../../redux/reducers/patient'
import { useAction } from '../../utils/hooks'
import Column from '../common/Column'
import PageContainer from '../common/PageContainer'

import Info from './Info'
import QuestionAnswers from './QuestionAnswers'
import Steps from './Steps'

const Patient = () => {
  const { id } = useParams()

  // The last value, 'since' is hardcoded to 0
  // This is done to always fetch all of the patient's data including all step data at once.
  // Client then decides what data to display and what not as is the case with question answers.
  const dispatchGetPatient = useAction(getPatient, id, 0)
  const dispatchClearPatient = useAction(clearPatient)

  const patient = useSelector(s => s.patient.patient)
  const questionsInitialized = useSelector(s => s.question.questionsInitialized)

  useEffect(() => {
    dispatchGetPatient()
    return dispatchClearPatient
  }, [id])

  if (!patient || !questionsInitialized) return <p>{t`Ladataan potilasta...`}</p>

  return (
    <PageContainer>
      <PatientLayoutContainer>
        <PatientInfoContainer>
          <Info />
        </PatientInfoContainer>
        <PatientStepsContainer>
          <Steps />
        </PatientStepsContainer>
        <PatientQuestionAnswersContainer>
          <QuestionAnswers />
        </PatientQuestionAnswersContainer>
      </PatientLayoutContainer>
    </PageContainer>
  )
}

const PatientLayoutContainer = styled.div`
  display: grid;
  gap: ${p => p.theme.spacing.large};
  grid-auto-rows: minmax(100px, auto);

  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'info  .     qna qna'
    'steps steps qna qna'
    'steps steps qna qna'
    'steps steps qna qna'
    '  .     .   qna qna'
    '  .     .   qna qna';

  @media (max-width: ${props => props.theme.breakpoints.tabletDown}) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
      '. info  .     .     .'
      '. steps steps steps .'
      '. steps steps steps .'
      '. steps steps steps .'
      '. qna   qna   qna   .'
      '. qna   qna   qna   .';
  }
`

const PatientInfoContainer = styled(Column)`
  grid-area: info;
`

const PatientStepsContainer = styled(Column)`
  grid-area: steps;
`

const PatientQuestionAnswersContainer = styled(Column)`
  grid-area: qna;
  @media (min-width: ${props => props.theme.breakpoints.tabletUp}) {
    height: 90vh;
    overflow-y: auto;
  }
`

export default Patient
