import { reducer } from '../reducer'
import { action } from '../action'

export const toggleSideBar = () => ({
  type: 'TOGGLE_SIDE_BAR',
})

export const initialState = {
  sideBarOpen: false,
}

export default reducer(
  action('TOGGLE_SIDE_BAR', (action, state) => ({
    sideBarOpen: !state.sideBarOpen,
  }))
)(initialState)
