import React from 'react'
import styled from 'styled-components'

import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria'

import CrossIcon from '../../assets/Cross.svg'
import { IconButton } from './buttons'
import { Heading1 } from './typography'

const Modal = props => {
  const { title, children, onClose } = props

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = React.useRef()
  const { overlayProps, underlayProps } = useOverlay(props, ref)

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll()
  const { modalProps } = useModal()

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref)

  return (
    <ModalUnderLay {...underlayProps}>
      <FocusScope contain restoreFocus autoFocus>
        <ModalContainer {...overlayProps} {...dialogProps} {...modalProps} ref={ref}>
          <TitleRow>
            <Heading1 {...titleProps}>{title}</Heading1>
            <IconButton onClick={onClose} icon={<CrossIcon />} />
          </TitleRow>

          {children}
        </ModalContainer>
      </FocusScope>
    </ModalUnderLay>
  )
}

const ModalUnderLay = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  background-color: ${p => p.theme.color.white};
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.xLarge};
  border-radius: ${p => p.theme.spacing.regular};

  gap: ${p => p.theme.spacing.large};
  min-width: 250px;
  min-height: 100px;
  max-width: min(900px, 90%);
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: ${p => p.theme.spacing.xxLarge};
`
export default Modal
