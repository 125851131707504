import React, { useCallback } from 'react'
import styled from 'styled-components'

const FormContainer = styled.form`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: ${p => p.maxWidth ?? '350px'};
`

const Form = ({ onSubmit, children, ...p }) => {
  const onSubmitWrapper = useCallback(
    e => {
      e.preventDefault()
      if (onSubmit) onSubmit(e)
    },
    [onSubmit]
  )

  return (
    <FormContainer onSubmit={onSubmitWrapper} {...p}>
      {children}
    </FormContainer>
  )
}

export default Form
